import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: '/',
        meta: { requiresAuth: true },
    },
    {
        path: '/',
        component: () => import('../views/home/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/empresas',
        component: () => import('../views/empresas/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/financeiro/:emp',
        component: () => import('../views/financeiro/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/planos',
        component: () => import('../views/planos/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/contatos',
        component: () => import('../views/contatos/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/usuarios',
        component: () => import('../views/usuarios/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/anuncios',
        component: () => import('../views/anuncios/Index.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/relatorio-contatos',
        component: () => import('../views/relatorios/RelatorioContatos.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        component: () => import('../views/login/Index.vue'),
        meta: { guest: true },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (sessionStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath },
            });
        }

        next();
    }

    next();
});

export default router;
